import { useEffect, useState } from "react";
import {
  HiOutlineDocumentText,
  HiOutlineListBullet,
  HiOutlinePaperAirplane,
  HiOutlinePaperClip,
  HiOutlinePlus,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi2";
/* import { TabPanel } from "./TabPanel"; */
import moment from "moment";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Input } from "../components/Input";
import { PiSignature } from "react-icons/pi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import api from "../components/api/api";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Modal } from "../components/Modal";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const InvioEmail = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const createTabs = location?.state?.selectedSinistri?.map((sinistro) => {
        let riferimento = sinistro?.riferimento;
        let data = sinistro?.data
          ? moment(sinistro?.data).format("YY") + " "
          : "";
        let condominio =
          sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
        let danno = sinistro?.danno !== "" ? sinistro?.danno + " " : " ";
        let persone = sinistro?.persone ? sinistro?.persone : "";
        if (sinistro?.persone?.length >= 3) {
          persone = sinistro?.persone?.split(",")?.slice(0, 3);
        }
        return (
          <Tab
            label={
              riferimento +
              "/" +
              data +
              " " +
              condominio +
              " " +
              danno +
              " " +
              persone
            }
          />
        );
      });
      setTabs([...createTabs]);
    })();
  }, []);
  return (
    <div div className="page-container ">
      <div className="h-[60vh] grid grid-cols-2">
        <div className="col-span-2 flex h-full">
          {tabs?.length > 0 && (
            <div className="w-full">
              <Tabs
                value={value}
                textColor="inherit"
                variant="scrollable"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                {tabs}
              </Tabs>
              {location?.state?.selectedSinistri?.map((sinistro, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <div className="w-full">
                      <EmailForm
                        type={location.state?.type}
                        sinistro={sinistro}
                        testo={location.state?.testo}
                      />
                    </div>
                  </TabPanel>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmailForm = ({ type, sinistro, testo }) => {
  const [emailsAgenzia, setEmailsAgenzia] = useState([]);
  const [emailPrincipaleAgenzia, setEmailPrincipaleAgenzia] = useState([]);
  const [amministratore, setAmministratore] = useState([]);
  const [multiDestinatari, setMultiDestinatari] = useState({
    perito: 0,
    agenzia: 0,
    danneggiati: 0,
  });

  const [openListaReferenti, setOpenListaReferenti] = useState(false);
  const [referenti, setReferenti] = useState([]);

  const [sending, setSending] = useState(false);
  const [persone, setPersone] = useState([]);
  const [studiPeritali, setStudiPeritali] = useState([]);
  const [periti, setPeriti] = useState([]);
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });
  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const handleOperReferenti = async () => {
    setOpenListaReferenti(true);
    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + sinistro.id_amministratore
    );
    setReferenti(
      responseEmailAmministratori.data.data?.filter(
        (ref) => ref.principale !== 1
      )
    );
  };
  const handleSendEmail = async () => {
    if (mail.destinatari) {
      setSending(true);
      try {
        await api.post("email/", {
          mittente: mail.mittente,
          alias: mail.alias,
          password: mail.password,
          destinatari: mail.destinatari,
          copia: mail?.copia,
          copiaNascosta: mail?.copiaNascosta,
          oggetto: mail.oggetto,
          testo: mail.testo,
          firma: mail.firma,
          allegati: mail?.allegati ? [mail?.allegati] : "",
          utente: sessionStorage.getItem("Username"),
          sinistro: sinistro.id,
          tipologia: type,
        });
        toast.success("Email inviata!");
      } catch (error) {
        toast.error("Errore durante l'invio!");
      }
      setSending(false);
    } else {
      toast.error("Campo Destinatari obbligatoro!");
    }
  };

  useEffect(() => {
    (async () => {
      let responseAgenzia = await api.get("agenzie");
      const ag = responseAgenzia.data.data.filter(
        (el) => el.id === sinistro?.id_agenzia
      )[0];
      setEmailsAgenzia(ag?.emails);
      setEmailPrincipaleAgenzia(ag?.email);
      let responseAmministratori = await api.get("amministratori");
      let amm = responseAmministratori.data.data?.filter(
        (el) => el.id === sinistro.id_amministratore
      )[0];
      setAmministratore(amm);
      let responseEmailAmministratori = await api.get(
        "amministratori/referente/" + amm?.id
      );
      let responseRelPersone = await api.get(
        "persone_interessate/sinistro/" + sinistro?.id
      );
      let maxThreePersone = responseRelPersone.data.data;
      if (responseRelPersone.data.data?.length >= 3) {
        maxThreePersone = responseRelPersone.data.data?.slice(0, 3);
      }
      setPersone(responseRelPersone.data.data);
      let responseUtenti = await api.get(
        "utenti/" + sessionStorage.getItem("Username")
      );

      let persOggetto = maxThreePersone?.map((pers) => " " + pers?.cognome);
      persOggetto = persOggetto?.join("");

      //OGGETTO MAIL
      let riferimento = sinistro?.riferimento;
      let data = sinistro?.data
        ? moment(sinistro?.data).format("YY") + " "
        : "";
      let condominio =
        sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
      let ol_scheda =
        sinistro?.ol_scheda !== ""
          ? "scheda " + sinistro?.ol_scheda + " "
          : " ";
      let n_sinistro = sinistro?.n_sinistro ? sinistro?.n_sinistro + " " : "";
      let rif_perito = sinistro?.rif_perito ? sinistro?.rif_perito + " " : "";

      let oggetto =
        riferimento +
        "/" +
        data +
        condominio +
        ol_scheda +
        n_sinistro +
        persOggetto +
        " " +
        rif_perito;
      let destinatari = "";
      let copia = "";
      let testo_aggintivo = "";
      let allegato = "";
      const urlParams = new URLSearchParams();
      const urlParamsInviata = new URLSearchParams();
      urlParams.append("id", sinistro?.id);
      urlParamsInviata.append("id", sinistro?.id);
      let responseEmail;
      console.log(type);
      //IN BASE ALLA TIPOLOGIA DI EMAIL SETTO DESTINATARI DIFFERENTI
      switch (type) {
        case "Amministrazione":
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          break;
        case "Pratica":
          if (sinistro?.id_perito) {
            let responsePeriti = await api.get(
              "studi_peritali/periti/" + sinistro?.id_studio_peritale
            );
            setPeriti(responsePeriti.data.data);
          }
          let responseStudiPeritali = await api.get(
            "studi_peritali/" + sinistro?.id_studio_peritale
          );
          setStudiPeritali(responseStudiPeritali.data.data);

          break;
        /* case "Invio Documentazione":
          let responsePeritiDoc = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          //CONTROLLO IL PERITO DI RIFERIMENTO
          const emailPeritoDoc = responsePeritiDoc?.data.data.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPerito) {
            destinatari = emailPeritoDoc;
          } else {
            destinatari = responsePeritiDoc.data.data[0]?.email_studio;
          }
          console.log(destinatari);
          break; */
        case "Sinistro":
          let responseDenunce = await api.get(
            "denunce/sinistro/" + sinistro.id
          );
          if (responseDenunce.data.data?.length > 0) {
            allegato = responseDenunce.data.data[0]?.id_file;
          }
          urlParams.append("tipologia", "Sinistro");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo + `\n\nPrecedenti sollecitazioni:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          destinatari = ag?.email;
          break;
        case "Invio Atto":
          let responsePeritii = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          //CONTROLLO IL PERITO DI RIFERIMENTO
          const emailPerito = responsePeritii?.data.data.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPerito) {
            destinatari = emailPerito;
          } else {
            destinatari = responsePeritii.data.data[0]?.email_studio;
          }
          urlParams.append("tipologia", "Invio Documentazione");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo = testo_aggintivo + `\n\nDocumentazione inviata:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          break;
        case "Ricezione Atto":
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          urlParams.append("tipologia", "Ricezione Atto");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          urlParamsInviata.append("tipologia", "Atto Da Firmare");
          let responseEmailInviata = await api.get(`email/sinistro/`, {
            params: urlParamsInviata,
          });
          if (responseEmailInviata.data.data?.length > 0) {
            allegato = responseEmailInviata.data.data[0]?.id_file;
          }
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo + `\n\nPrecedenti sollecitazioni:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          break;
        default:
          break;
      }
      setMail({
        ...mail,
        mittente: amm?.email ? amm.email : "",
        alias: amm?.alias_check === 1 ? amm?.alias : "",
        password: amm?.password_email ? amm.password_email : "",
        destinatari: destinatari,
        copia: copia,
        copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
        firma: amm.firma ? amm.firma : "",
        oggetto: oggetto,
        testo: testo + " " + testo_aggintivo,
        allegati: allegato,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let agenzia;
      let perito;
      let danneggiati;
      if (multiDestinatari.agenzia === 1) {
        emailPrincipaleAgenzia === "" &&
          toast.warning("Nessun indirizzo email presente!");
        agenzia = emailPrincipaleAgenzia ? emailPrincipaleAgenzia + "," : "";
      } else {
        agenzia = "";
      }
      if (multiDestinatari.perito === 1) {
        let destinatari;
        if (
          sinistro?.id_perito &&
          periti?.filter((p) => sinistro.id_perito === p.id)[0]?.email
        ) {
          //se il perito è stato selezionato
          destinatari = periti?.filter((p) => sinistro.id_perito === p.id)[0]
            ?.email;
        } else {
          //se il perito non esiste oppure se esiste e non ha l'indirizzo email
          toast.warning("Nessun indirizzo email del perito!");
          if (sinistro?.id_studio_peritale && studiPeritali[0]?.email) {
            destinatari = studiPeritali[0]?.email
              ? studiPeritali[0]?.email + ","
              : "";
          } else {
            destinatari = "";
            toast.warning("Nessun indirizzo email dello studio peritale!");
          }
        }
        perito = destinatari;
      } else {
        perito = "";
      }
      if (multiDestinatari.danneggiati === 1) {
        const emailPersone = persone
          ?.filter((persone) => persone.email)
          .map((el) => el?.email);
        emailPersone.length === 0 &&
          toast.warning("Nessun indirizzo email presente!");
        danneggiati = emailPersone.length > 0 ? emailPersone + "," : "";
      } else {
        danneggiati = "";
      }
      setMail({
        ...mail,
        destinatari: agenzia + perito + danneggiati,
      });
    })();
  }, [multiDestinatari]);

  return (
    <div className="grid grid-cols-2 gap-2 h-full px-2">
      <div className="col-span-2 text-2xl text-center">{type}</div>
      <div className="col-span-2">
        <Input
          label={"Mittente"}
          type="text"
          name="mittente"
          onChange={handleChangeMail}
          value={mail?.mittente}
          icon={<HiOutlineUser className="text-2xl" />}
        />
      </div>
      {mail?.alias && (
        <div className="col-span-2">
          <Input
            label={"Alias"}
            type="text"
            name="alias"
            onChange={handleChangeMail}
            value={mail?.alias}
            icon={<HiOutlineUser className="text-2xl" />}
          />
        </div>
      )}
      {type === "Pratica" && (
        <>
          <div className="col-span-2 flex gap-3">
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="perito"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.perito === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    perito: multiDestinatari?.perito === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Perito
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="agenzia"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.agenzia === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    agenzia: multiDestinatari?.agenzia === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Agenzia
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="danneggiati"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.danneggiati === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    danneggiati: multiDestinatari?.danneggiati === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Danneggiati
              </span>
            </div>
          </div>
        </>
      )}
      <div className="col-span-2 w-full flex items-end gap-2">
        <Input
          label={"Destinatari"}
          type="text"
          name="destinatari"
          onChange={handleChangeMail}
          value={mail?.destinatari}
          icon={<HiOutlineUser className="text-2xl" />}
        />
        {type === "Amministrazione" && (
          <div
            className="icon-container-green group"
            onClick={handleOperReferenti}
          >
            <HiOutlineUsers className="icon-green" />
          </div>
        )}
      </div>
      {/* <div className="col-span-2">
        <Input
          label={"In Copia"}
          type="text"
          name="copia"
          onChange={handleChangeMail}
          value={mail?.copia}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div> */}
      <div className="col-span-2">
        <Input
          label={"In Copia Nascosta"}
          type="text"
          name="copiaNascosta"
          onChange={handleChangeMail}
          value={mail?.copiaNascosta}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"Oggetto"}
          type="text"
          name="oggetto"
          onChange={handleChangeMail}
          value={mail?.oggetto}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      {mail?.allegati && (
        <div className="col-span-2 ">
          <span className="text-lg font-normal">Allegato:</span>
          <div className="relative">
            <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
              <HiOutlinePaperClip className="text-2xl" />
            </span>
            <div className="input flex items-center flex-wrap">
              <div
                className="underline"
                onClick={() =>
                  window.open(
                    `https://drive.google.com/file/d/${mail?.allegati}/view?usp=drivesdk`,
                    "_blank"
                  )
                }
              >
                File.pdf
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-2 ">
        <span className="text-lg font-normal">Testo</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <HiOutlineDocumentText className="text-2xl" />
          </span>

          <textarea
            rows={5}
            type="text"
            name="testo"
            className="input flex items-center"
            placeholder="Inserisci Testo"
            onChange={handleChangeMail}
            value={mail?.testo}
          />
        </div>
      </div>
      {/* <div className="col-span-2 ">
        <span className="text-lg font-normal">Firma</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <PiSignature className="text-2xl" />
          </span>

          <textarea
            rows={5}
            type="text"
            name="testo"
            className="input flex items-center"
            //placeholder="Inserisci Testo"
            //onChange={handleChangeMail}
            value={mail?.firma}
          />
        </div>
      </div> */}
      <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
        <>
          {sending ? (
            <div
              className="icon-container-green group "
              onClick={() => toast.warning("Invio in corso!")}
            >
              <AiOutlineLoading3Quarters className="icon-green animate-spin" />
            </div>
          ) : (
            <div
              className="icon-container-green h-min group "
              onClick={handleSendEmail}
            >
              <HiOutlinePaperAirplane className="icon-green" />
            </div>
          )}
        </>
      </div>
      <Modal
        open={openListaReferenti}
        handleClose={async () => {
          setOpenListaReferenti(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Lista Referenti</h2>
        <div className="w-full  mt-6 h-80">
          <DataGridPro
            rows={referenti}
            columns={[
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "nome",
                headerName: "Nome",
                width: 150,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "telefono",
                headerName: "Telefono",
                width: 100,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
            ]}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div>
      </Modal>
    </div>
  );
};
