import { useEffect, useState } from "react";
import {
  HiExclamationCircle,
  HiMiniCheckCircle,
  HiOutlineArrowPath,
  HiOutlineCalendar,
  HiOutlineCheck,
  HiOutlineDocumentText,
  HiOutlineListBullet,
  HiOutlinePaperAirplane,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi2";
import { HiOutlineMail, HiOutlineRefresh } from "react-icons/hi";
import { Input } from "./Input";
import { toast } from "react-toastify";
import { DataInput } from "./DataInput";
import moment from "moment";
import jsPDF from "jspdf";
import api from "./api/api";
import { Modal } from "./Modal";
import { PiSignature } from "react-icons/pi";
import { ModalDelete } from "./ModalDelete";
import { useLocation } from "react-router-dom";
import { ModalXL } from "./ModalXL";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const ComponentDenuncia = ({
  dati,
  storico,
  operazione,
  rifSinistro,
  sinistro,
  getDenunce,
  setTab,
}) => {
  const location = useLocation();
  const [openStampa, setOpenStampa] = useState(false);
  const [storicoDenunce, setStoricoDenunce] = useState([]);
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });

  const [pdf, setPdf] = useState(null);

  const [denuncia, setDenuncia] = useState(null);
  const [riferimento, setRiferimento] = useState(null);
  const [emailsAgenzia, setEmailsAgenzia] = useState([]);
  const [emailsInCopia, setEmailsInCopia] = useState([]);
  const [emailPrincipale, setEmailPrincipale] = useState([]);
  const [amministratore, setAmministratore] = useState([]);
  const [persone, setPersone] = useState([]);
  const [verify, setVerify] = useState({
    amministratore: true,
    amministratore_email: true,
    amministratore_cell: true,
    amministratore_indirizzo: true,
    compagnia: true,
    agenzia: true,
    agenzia_email: true,
    agenzia_email_principali: true,
    sinistro_data: true,
    sinistro_tipologia: true,
    sinistro_condominio: true,
    sinistro_polizza: true,
    sinistro_riferimento: true,
    sinistro_tipologia: true,
    sinistro_tipologia_testo: true,
    sinistro_danno: true,
    persone: true,
  });

  const [openListaReferenti, setOpenListaReferenti] = useState(false);
  const [referenti, setReferenti] = useState([]);

  const [openDelete, setOpenDelete] = useState(false);

  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const handleOperReferenti = async () => {
    setOpenListaReferenti(true);
    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + sinistro.id_amministratore
    );
    setReferenti(
      responseEmailAmministratori.data.data?.filter(
        (ref) => ref.principale !== 1
      )
    );
  };

  const loadData = async () => {
    let responseAgenzia = await api.get("agenzie");
    const ag = responseAgenzia.data.data.filter(
      (el) => el.id === sinistro?.id_agenzia
    )[0];
    //let responseAg = await api.get("agenzie/email/" + sinistro?.id_agenzia);
    setEmailsAgenzia(ag?.emails);
    setEmailPrincipale(ag?.email);
    let responseAmministratori = await api.get("amministratori");
    let amm = responseAmministratori.data.data?.filter(
      (el) => el.id === sinistro.id_amministratore
    )[0];
    setAmministratore(amm);

    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + sinistro.id_amministratore
    );
    setEmailsInCopia(
      responseEmailAmministratori.data.data
        ?.filter((responsabili) => responsabili.principale === 1)
        .map((res) => res.email)
    );

    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro?.id
    );
    /* let maxThreePersone = responseRelPersone.data.data;
    if (responseRelPersone.data.data?.length >= 3) {
      maxThreePersone = responseRelPersone.data.data?.slice(0, 3);
    } */
    setPersone(responseRelPersone.data.data);
    let checkVerify = { ...verify };

    //Dati Intestazione
    if (sinistro?.amministratore) {
      checkVerify = { ...checkVerify, amministratore: false };
    }
    if (amm?.email) {
      checkVerify = { ...checkVerify, amministratore_email: false };
    }
    if (amm?.telefono) {
      checkVerify = { ...checkVerify, amministratore_cell: false };
    }
    if (amm?.via && amm?.cap && amm?.paese && amm?.provincia) {
      checkVerify = { ...checkVerify, amministratore_indirizzo: false };
    }
    //Dati Recapiti
    if (sinistro?.compagnia) {
      checkVerify = { ...checkVerify, compagnia: false };
    }
    if (sinistro?.agenzia) {
      checkVerify = { ...checkVerify, agenzia: false };
    }
    if (ag?.emails) {
      checkVerify = { ...checkVerify, agenzia_email: false };
    }
    if (ag?.email) {
      checkVerify = { ...checkVerify, agenzia_email_principali: false };
    }
    //Dati Sinstro
    if (sinistro?.data) {
      checkVerify = { ...checkVerify, sinistro_data: false };
    }
    if (sinistro?.tipologia_sinistro) {
      checkVerify = { ...checkVerify, sinistro_tipologia: false };
    }
    if (sinistro?.condominio) {
      checkVerify = { ...checkVerify, sinistro_condominio: false };
    }
    if (sinistro?.n_polizza) {
      checkVerify = { ...checkVerify, sinistro_polizza: false };
    }
    if (sinistro?.riferimento) {
      checkVerify = { ...checkVerify, sinistro_riferimento: false };
    }
    if (sinistro?.tipologia_sinistro_testo) {
      checkVerify = { ...checkVerify, sinistro_tipologia_testo: false };
    }
    //Dati Persone Interessate
    if (sinistro?.danno) {
      checkVerify = { ...checkVerify, sinistro_danno: false };
    }
    if (responseRelPersone.data.data?.length > 0) {
      checkVerify = { ...checkVerify, persone: false };
    }

    setVerify(checkVerify);
  };
  const handleSendEmail = async () => {
    let cartella;
    let responseDoc = await api.get(
      "documentazioni/sinistro/" + location.state.sinistro?.id
    );
    if (responseDoc.data.data.length > 0) {
      cartella = responseDoc.data.data[0]?.id_folder;
      if (mail.destinatari) {
        // Create FormData object and append the PDF Blob
        const formData = new FormData();
        formData.append("pdf", pdf, `${mail.oggetto}.pdf`); // Specify filename
        formData.append("mittente", mail.mittente);
        formData.append("fileName", `${mail.oggetto}.pdf`);
        formData.append("alias", mail.alias);
        formData.append("password", mail.password);
        formData.append("destinatari", mail.destinatari);
        formData.append("copia", mail?.copia);
        formData.append("copiaNascosta", mail?.copiaNascosta);
        formData.append("oggetto", mail.oggetto);
        formData.append("testo", mail.testo);
        formData.append("firma", mail.firma);
        formData.append("cartella", cartella);
        formData.append(
          "data",
          moment(denuncia?.data_invio).format("YYYY-MM-DD")
        );
        formData.append("sinistro", denuncia?.id_sinistro);
        formData.append("utente", sessionStorage.getItem("Username"));
        try {
          await api.post("denunce/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          toast.success("Email inviata!");
        } catch (error) {
          toast.error("Errore durante l'invio!");
        }
        setOpenStampa(false);
        setTimeout(() => {
          setTab(1);
        }, 1000);
      } else {
        toast.error("Campo Destinatari obbligatoro!");
      }
    } else {
      toast.error("Cartella Documentazione inesistente!");
    }
  };
  const handleCheckEmail = async (integrazione) => {
    /* let responseAg = await api.get("agenzie/email/" + sinistro?.id_agenzia);
    setEmailsAgenzia(responseAg.data.data); */
    let responseAgenzia = await api.get("agenzie");
    const ag = responseAgenzia.data.data.filter(
      (el) => el.id === sinistro?.id_agenzia
    )[0];
    setEmailsAgenzia(ag?.emails);
    setEmailPrincipale(ag?.email);
    let responseAmministratori = await api.get("amministratori");
    let amm = responseAmministratori.data.data?.filter(
      (el) => el.id === sinistro.id_amministratore
    )[0];
    setAmministratore(amm);
    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + sinistro.id_amministratore
    );
    let referentiPrincipali = responseEmailAmministratori.data.data
      ?.filter((responsabili) => responsabili.principale === 1)
      .map((res) => res.email);
    setEmailsInCopia(referentiPrincipali);

    /* let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + amm?.id
    ); */

    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro?.id
    );
    let maxThreePersone = responseRelPersone.data.data;
    if (responseRelPersone.data.data?.length >= 3) {
      maxThreePersone = responseRelPersone.data.data?.slice(0, 3);
    }
    setPersone(responseRelPersone.data.data);
    let responseUtenti = await api.get(
      "utenti/" + sessionStorage.getItem("Username")
    );
    let persOggetto = maxThreePersone?.map((pers) => " " + pers?.cognome);
    persOggetto = persOggetto?.join("");

    let responseTestoEmail = await api.get("tipologie_mail/" + "Denuncia");

    let riferimento = sinistro?.riferimento;
    let data = sinistro?.data ? moment(sinistro?.data).format("YY") + " " : "";
    let condominio =
      sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
    let ol_scheda =
      sinistro?.ol_scheda !== "" ? "scheda " + sinistro?.ol_scheda + " " : " ";
    let n_sinistro = sinistro?.n_sinistro ? sinistro?.n_sinistro + " " : " ";
    let rif_perito = sinistro?.rif_perito ? sinistro?.rif_perito + " " : " ";

    let oggetto =
      riferimento +
      "/" +
      data +
      condominio +
      ol_scheda +
      n_sinistro +
      persOggetto +
      " " +
      rif_perito;
    //CARICO I DESTINATATI E CC
    if (amm?.email !== "" || amm?.password_email !== "") {
      if (integrazione) {
        setMail({
          ...mail,
          mittente: amm?.email ? amm.email : "",
          alias: amm?.alias_check === 1 ? amm?.alias : "",
          password: amm?.password_email ? amm.password_email : "",
          destinatari: ag?.email,
          copia: (ag?.emails ? ag?.emails + "," : "") + referentiPrincipali,
          copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
          firma: amm.firma ? amm.firma : "",
          oggetto: "INTEGRAZIONE " + oggetto,
          testo: responseTestoEmail.data.data[0]?.testo,
        });
        exportPDF(
          ag?.emails,
          responseAmministratori.data.data?.filter(
            (el) => el.id === sinistro.id_amministratore
          )[0],
          responseRelPersone.data.data,
          true
        );
      } else if (denuncia?.data_invio) {
        setMail({
          ...mail,
          mittente: amm?.email ? amm.email : "",
          alias: amm?.alias_check === 1 ? amm?.alias : "",
          password: amm?.password_email ? amm.password_email : "",
          destinatari: ag?.email,
          copia:
            (ag?.emails ? ag?.emails + "," : "") + referentiPrincipali,
          copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
          firma: amm.firma ? amm.firma : "",
          oggetto: oggetto,
          testo: responseTestoEmail.data.data[0]?.testo,
        });
        exportPDF(
          ag?.emails,
          responseAmministratori.data.data?.filter(
            (el) => el.id === sinistro.id_amministratore
          )[0],
          responseRelPersone.data.data,
          false
        );
      } else {
        toast.error("Inserisci data!");
      }
    } else {
      toast.error("Credenziali mittente insufficenti!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.post(`email/sinistro/elimina/`, {
        denuncia: denuncia,
      });
      toast.success("Denuncia eliminata!");
      setOpenDelete(false);
      getDenunce();
    } catch (error) {
      toast.error("Errore durante l'eliminazione!");
    }
  };

  const exportPDF = (emailAgenzie, amm, pers, integrazione) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size, true);
    let mesi = {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    };
    const emails = emailAgenzie?.split(",");

    doc.setFont("verdana", "bold");
    doc.setFontSize(12);
    doc.text(
      doc.splitTextToSize(
        `St. ${sinistro?.amministratore}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      30
    );
    doc.setTextColor(0, 0, 238);
    doc.setDrawColor(0, 0, 238);
    doc.text(
      doc.splitTextToSize(
        `${amm?.email}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      42
    );
    doc.line(
      33,
      43,
      33 + doc.getStringUnitWidth(`${amm?.email}`) * doc.internal.getFontSize(),
      43
    );
    doc.setDrawColor(0, 0, 0);
    doc.setTextColor(0, 0, 0);
    doc.text(
      doc.splitTextToSize(
        `${amm?.telefono}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      56
    );
    doc.text(
      doc.splitTextToSize(
        `${amm?.via} ${amm?.cap} ${amm?.paese} (${amm?.provincia})`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      68
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `${amm?.paese}, ${moment(denuncia?.data_invio).format("DD")} ${
          mesi[moment(denuncia?.data_invio).format("M")]
        } ${moment(denuncia?.data_invio).format("YYYY")}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      95
    );
    doc.text(
      doc.splitTextToSize(`Spett.le`, doc.internal.pageSize.getWidth() - 60),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Spett.le`) * doc.internal.getFontSize() -
        40,
      110
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `${sinistro?.compagnia}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${sinistro?.compagnia}`) *
          doc.internal.getFontSize() -
        40,
      122
    );
    doc.text(
      doc.splitTextToSize(
        `Ag. ${sinistro?.agenzia}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Ag. ${sinistro?.agenzia}`) *
          doc.internal.getFontSize() -
        40,
      134
    );
    doc.setFont("verdana", "normal");
    doc.setFontSize(10);

    doc.text(
      doc.splitTextToSize(
        `${emailPrincipale}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${emailPrincipale}`) *
          doc.internal.getFontSize() -
        40,
      146
    );
    {
      emails?.map((email, index) =>
        doc.text(
          doc.splitTextToSize(
            `${email}`,
            doc.internal.pageSize.getWidth() - 60
          ),
          doc.internal.pageSize.getWidth() -
            doc.getStringUnitWidth(`${email}`) * doc.internal.getFontSize() -
            40,
          156 + index * 10
        )
      );
    }
    doc.setFontSize(12);
    doc.setFont("verdana", "bold");

    doc.text(
      `OGGETTO: Denuncia sinistro per danni da ${
        sinistro?.tipologia_sinistro ? sinistro?.tipologia_sinistro
 : ""
      } del ${moment(sinistro?.data).format("DD/MM/YYYY")}`,
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(
          `OGGETTO: Denuncia sinistro per danni da ${
            sinistro?.tipologia_sinistro ? sinistro?.tipologia_sinistro
 : ""
          } ${moment(sinistro?.data).format("DD/MM/YYYY")}`
        ) *
          doc.internal.getFontSize()) /
          2,
      215
    );
    doc.text(
      `${sinistro?.condominio} ${sinistro?.n_polizza? "POL " + sinistro?.n_polizza
        : ""}`,
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(
          `${sinistro?.condominio} ${sinistro?.n_polizza? "POL " + sinistro?.n_polizza
        : ""}`
        ) *
          doc.internal.getFontSize()) /
          2,
      230
    );
    doc.text(
      `NS RIFERIMENTO ${sinistro?.riferimento}/${moment(sinistro?.data).format(
        "YYYY"
      )}`,
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(
          `NS RIFERIMENTO ${sinistro?.riferimento}/${moment(
            sinistro?.data
          ).format("YYYY")}`
        ) *
          doc.internal.getFontSize()) /
          2,
      245
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `${sinistro?.tipologia_sinistro_testo? sinistro?.tipologia_sinistro_testo
          : ""}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      280
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `${sinistro?.custode ? "Riferimento " +sinistro?.custode : ""}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      316
    );
    doc.text(
      doc.splitTextToSize(
        `${sinistro?.danno ? sinistro?.danno : ""}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      328
    );
    let height = 340;
    {
      pers?.map((p, index) => {
        doc.text(
          doc.splitTextToSize(
            `${p?.cognome} ${p?.nome} ${p?.piano ? p?.piano + " P " : ""} ${
              p?.telefono
            } ${p?.email}`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          height
        );
        height = height + 12;

        if (p?.riferimento) {
          height = height - 3;
          doc.setFont("verdana", "normal");
          doc.setFontSize(9);
          doc.text(
            doc.splitTextToSize(
              `${p?.riferimento}`,
              doc.internal.pageSize.getWidth() - 80
            ),
            33,
            height
          );
          doc.setFont("verdana", "bold");
          doc.setFontSize(12);
          height = height + 12;
        }
      });
    }
    if (height >= 580) {
      doc.addPage();
      height = 40;
    }

    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `Vogliate aprire regolare posizione di sinistro ed incaricare un perito per l’accertamento dei danni, comunicandoci cortesemente numero di sinistro e studio peritale incaricato.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      height + 20
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `NOTA PER IL PERITO`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      height + 70
    );
    doc.line(
      33,
      height + 72,
      33 +
        doc.getStringUnitWidth(`NOTA PER IL PERITO`) *
          doc.internal.getFontSize(),
      height + 72
    );
    doc.setFont("verdana", "normal");
    /* doc.text(
      doc.splitTextToSize(
        `Per ogni comunicazione in merito al sinistro nonché per la sua definizione, vi invito a prendere contatto diretto con il nostro collaboratore, il perito De Zolt, al n. 348.8547721 o via e-mail all’indirizzo ${amm?.email} citando il nostro riferimento.`,
        doc.internal.pageSize.getWidth() - 80
        ),
        33,
        height + 160
        ); */
    doc.text(
      doc.splitTextToSize(
        `Per ogni comunicazione in merito al sinistro nonché per la sua definizione, vi invito a prendere contatto diretto con il nostro collaboratore, il perito citando il nostro riferimento:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      height + 100
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(`De Zolt`, doc.internal.pageSize.getWidth() - 80),
      33,
      height + 130
    );
    doc.text(
      doc.splitTextToSize(`348.8547721`, doc.internal.pageSize.getWidth() - 80),
      33,
      height + 144
    );
    doc.setFont("verdana", "normal");
    // Set the text color to blue
    doc.setTextColor(0, 0, 255); // RGB for blue
    doc.text(
      doc.splitTextToSize(
        `${amm?.email}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      height + 156
    );
    doc.setDrawColor(0, 0, 255); // Set the line color to blue
    doc.setLineWidth(0.5); // Set the line width for the underline
    doc.line(
      33,
      height + 157,
      33 + doc.getTextWidth(`${amm?.email}`),
      height + 157
    ); // Draw the underline
    doc.setTextColor(0, 0, 0); // RGB for blue

    doc.text(
      doc.splitTextToSize(
        `Colgo l’occasione per porgere cordiali saluti.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      height + 200
    );
    doc.setFontSize(16);
    doc.setFont("verdana", "italic");
    doc.text(
      doc.splitTextToSize(
        `L'Amministratore`,
        doc.internal.pageSize.getWidth() - 80
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`L'Amministratore`) *
          doc.internal.getFontSize() -
        80,
      height + 240
    );
    doc.text(
      doc.splitTextToSize(
        `${amm?.nome}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${amm?.nome}`) * doc.internal.getFontSize() -
        80,
      height + 260
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
    // Create a Blob object from the PDF data
    const pdfBlob = new Blob([doc.output("blob")], { type: "application/pdf" });

    setPdf(pdfBlob);
    setOpenStampa(true);
    //handleSendEmail(formData);
  };

  useEffect(() => {
    (async () => {
      if (operazione === "new") {
        setDenuncia({
          id_sinistro: sinistro?.id,
          data_invio: new Date(),
        });
      } else {
        setDenuncia(dati);
        setStoricoDenunce(storico?.filter((el) => el.id !== dati.id));
        let riferimento = dati?.riferimento;
        let data = dati?.data ? moment(dati?.data).format("YY") + " " : "";
        let condominio =
          dati?.condominio !== "" ? dati?.condominio + " " : "Condominio ";
        let danno = dati?.danno !== "" ? dati?.danno + " " : "Danno ";
        let persone = dati?.persone ? dati?.persone : "";
        setRiferimento(
          riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone
        );
      }
      loadData();
    })();
  }, [dati]);
  return (
    <>
      {rifSinistro && (
        <div className="w-full flex items-center justify-center text-center text-2xl font-semibold mb-4">
          PRATICA RIF. - {riferimento}
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 px-2  h-[calc(100vh_-_320px)]  lg:h-[calc(100vh_-_250px)] overflow-y-auto">
        <div
          className={`grid grid-cols-2 gap-2 col-span-2 p-2 rounded-md ${
            operazione === "edit" && "bg-gray-200"
          }`}
        >
          <div className={`col-span-2 md:col-span-1 `}>
            <DataInput
              label={"Data Invio"}
              name="data_invio"
              onChange={(e) => {
                if (
                  moment(e).format("YYYY-MM-DD") >
                  moment(new Date()).format("YYYY-MM-DD")
                ) {
                  toast.warning(
                    "Attenzione! La data inserita è maggiore della data attuale"
                  );
                }
                setDenuncia({
                  ...denuncia,
                  data_invio: moment(e).format("YYYY-MM-DD"),
                });
              }}
              value={moment(denuncia?.data_invio)}
            />
          </div>
          {operazione === "edit" && (
            <div className={`col-span-2 md:col-span-1 `}>
              <DataInput
                label={"Data Effettiva"}
                name="data_invio_effettivo"
                disabled={true}
                value={moment(denuncia?.data_invio_effettivo)}
              />
            </div>
          )}
          {sessionStorage.getItem("admin") === "2" && operazione === "edit" && (
            <div className="col-span-2 md:col-span-1 ">
              <Input
                disabled={true}
                label={"Utente"}
                type="text"
                name="utente"
                value={denuncia?.utente}
                icon={<HiOutlineUser className="text-2xl" />}
              />
            </div>
          )}
          <div className="col-span-2 flex gap-4 justify-end flex-1">
            {operazione === "edit" ? (
              <>
                <div
                  className="icon-container-blue group"
                  onClick={() => handleCheckEmail(true)}
                >
                  <HiOutlineArrowPath className="icon-blue" />
                </div>
                <div
                  className="icon-container-red group"
                  onClick={() => setOpenDelete(true)}
                >
                  <HiOutlineTrash className="icon-red" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="icon-container-blue group"
                  onClick={() => handleCheckEmail(false)}
                >
                  <HiOutlineMail className="icon-blue" />
                </div>
              </>
            )}
          </div>
        </div>

        {storicoDenunce?.length > 0 && (
          <>
            <h3 className="col-span-2 flex text-xl justify-center">
              STORICO DENUNCE
            </h3>
            {storicoDenunce?.reverse()?.map((el, index) => (
              <>
                <div className="col-span-1">
                  <DataInput
                    label={"Data Invio N°" + (index + 1)}
                    name="data_invio"
                    value={moment(el?.data_invio)}
                  />
                </div>
                <div className="col-span-1">
                  <DataInput
                    label={"Data Effettiva N°" + (index + 1)}
                    name="data_invio"
                    value={moment(el?.data_invio_effettivo)}
                  />
                </div>
              </>
            ))}
          </>
        )}

        <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
        <h3 className="col-span-2 flex text-xl justify-center">DATI EMAIL</h3>
        <div className="col-span-2 lg:col-span-1 grid grid-cols-2 gap-2 border-2 border-sinistri-blue rounded-md p-2">
          <div className="col-span-2 text-lg font-semibold text-center">
            Dati Intestazione
          </div>
          <div className="col-span-2 flex-col flex gap-2">
            <div className="flex items-center gap-1">
              {verify.amministratore ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Amministratore: {sinistro?.amministratore}
            </div>
            <div className="flex items-center gap-1">
              {verify.amministratore_email ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Email: {amministratore?.email}
            </div>
            <div className="flex items-center gap-1">
              {verify.amministratore_cell ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Telefono: {amministratore?.telefono}
            </div>
            <div>
              <div className="flex items-center gap-1">
                {verify.amministratore_indirizzo ? (
                  <HiExclamationCircle className="text-sinistri-red text-2xl" />
                ) : (
                  <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
                )}{" "}
                Indirizzo:
              </div>
              <div className="ml-7">
                {amministratore?.via} {amministratore?.cap}{" "}
                {amministratore?.paese} ({amministratore?.provincia})
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 grid grid-cols-2 gap-2 border-2 border-sinistri-blue rounded-md p-2">
          <div className="col-span-2 text-lg font-semibold text-center">
            Dati Recapiti
          </div>
          <div className="col-span-2 flex-col flex gap-2">
            <div className="flex items-center gap-1">
              {verify.compagnia ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Compagnia: {sinistro?.compagnia}
            </div>
            <div className="flex items-center gap-1">
              {verify.agenzia ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Agenzia: {sinistro?.agenzia}
            </div>
            <div className="flex items-center gap-1">
              {verify.agenzia_email_principali ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Email Principale: {emailPrincipale}
            </div>
            <div className="flex items-center gap-1">
              {verify.agenzia_email && emailsInCopia?.length === 0 ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Emails In Copia: {emailsAgenzia}
              {emailsInCopia}
            </div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 grid grid-cols-2 gap-2 border-2 border-sinistri-blue rounded-md p-2">
          <div className="col-span-2 text-lg font-semibold text-center">
            Dati Sinstro
          </div>
          <div className="col-span-2 flex-col flex gap-2">
            <div className="flex items-center gap-1">
              {verify.sinistro_data ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Data:{" "}
              {sinistro?.data && moment(sinistro?.data).format("DD-MM-YYYY")}
            </div>
            <div className="flex items-center gap-1">
              {verify.sinistro_tipologia ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Tipologia Sinistro: {sinistro?.tipologia_sinistro}
            </div>
            <div className="flex items-center gap-1">
              {verify.sinistro_condominio ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Condominio: {sinistro?.condominio}
            </div>
            <div className="flex items-center gap-1">
              {verify.sinistro_polizza ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              N° Polizza: {sinistro?.n_polizza}
            </div>
            <div className="flex items-center gap-1">
              {verify.sinistro_riferimento ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Riferimento: {sinistro?.riferimento}
            </div>
            <div>
              <div className="flex items-center gap-1">
                {verify.sinistro_tipologia_testo ? (
                  <HiExclamationCircle className="text-sinistri-red text-2xl" />
                ) : (
                  <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
                )}{" "}
                Testo Tipologia Sinistro:
              </div>
              <div className="ml-7">{sinistro?.tipologia_sinistro_testo}</div>
            </div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 grid grid-cols-2 gap-2 border-2 border-sinistri-blue rounded-md p-2">
          <div className="col-span-2 text-lg font-semibold text-center">
            Dati Persone Interessate
          </div>
          <div className="col-span-2 flex-col flex gap-2">
            <div className="flex items-center gap-1">
              {verify.sinistro_danno ? (
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
              ) : (
                <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
              )}{" "}
              Danno: {sinistro?.danno}
            </div>
            {persone?.map((persona, index) => (
              <div className="">
                {/* {verify.persone ? (
                  <HiExclamationCircle className="text-sinistri-red text-2xl" />
                ) : (
                  <HiMiniCheckCircle className="text-sinistri-darkgreen text-2xl" />
                )}{" "} */}
                <span className="font-bold">Persona {index + 1}:</span>{" "}
                {persona?.cognome} {persona?.nome} {persona?.piano}{" "}
                {persona?.telefono} <br />
                {persona?.riferimento
                  ? "Riferimento: " + persona?.riferimento
                  : ""}
              </div>
            ))}
            {persone.length === 0 && (
              <div className="flex items-center gap-1">
                <HiExclamationCircle className="text-sinistri-red text-2xl" />
                Nessuna persona interessata presente
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalXL open={openStampa} handleClose={() => setOpenStampa(false)}>
        <div className="grid grid-cols-2 gap-2 h-full px-2">
          <div className="col-span-2 text-2xl text-center">Invio Denuncia</div>
          <div className="col-span-2">
            <Input
              label={"Mittente"}
              type="text"
              name="mittente"
              onChange={handleChangeMail}
              value={mail?.mittente}
              icon={<HiOutlineUser className="text-2xl" />}
            />
          </div>
          {mail?.alias && (
            <div className="col-span-2">
              <Input
                label={"Alias"}
                type="text"
                name="alias"
                onChange={handleChangeMail}
                value={mail?.alias}
                icon={<HiOutlineUser className="text-2xl" />}
              />
            </div>
          )}
          <div className="col-span-2 w-full flex items-end gap-2">
            <Input
              label={"Destinatari"}
              type="text"
              name="destinatari"
              onChange={handleChangeMail}
              value={mail?.destinatari}
              icon={<HiOutlineUser className="text-2xl" />}
            />
            <div
            className="icon-container-green group"
            onClick={handleOperReferenti}
          >
            <HiOutlineUsers className="icon-green" />
          </div>
          </div>
          <div className="col-span-2">
            <Input
              label={"In Copia"}
              type="text"
              name="copia"
              onChange={handleChangeMail}
              value={mail?.copia}
              icon={<HiOutlineUsers className="text-2xl" />}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"In Copia Nascosta"}
              type="text"
              name="copiaNascosta"
              onChange={handleChangeMail}
              value={mail?.copiaNascosta}
              icon={<HiOutlineUsers className="text-2xl" />}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"Oggetto"}
              type="text"
              name="oggetto"
              onChange={handleChangeMail}
              value={mail?.oggetto}
              icon={<HiOutlineUsers className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Testo</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineDocumentText className="text-2xl" />
              </span>

              <textarea
                rows={5}
                type="text"
                name="testo"
                className="input flex items-center"
                placeholder="Inserisci Testo"
                onChange={handleChangeMail}
                value={mail?.testo}
              />
            </div>
          </div>
          {/* <div className="col-span-2 ">
            <span className="text-lg font-normal">Firma</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <PiSignature className="text-2xl" />
              </span>

              <textarea
                rows={5}
                type="text"
                name="testo"
                className="input flex items-center"
                //placeholder="Inserisci Testo"
                //onChange={handleChangeMail}
                value={mail?.firma}
              />
            </div>
          </div> */}
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSendEmail}
            >
              <HiOutlinePaperAirplane className="icon-green" />
            </div>
          </div>
        </div>
      </ModalXL>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={
          "la Denuncia del " +
          moment(denuncia?.data_invio).format("DD-MM-YYYY") +
          " e crearne una nuova"
        }
        handleDelete={handleDelete}
      />
      <Modal
        open={openListaReferenti}
        handleClose={async () => {
          setOpenListaReferenti(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Lista Referenti</h2>
        <div className="w-full  mt-6 h-80">
          <DataGridPro
          ignoreDiacritics
            rows={referenti}
            columns={[
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "nome",
                headerName: "Nome",
                width: 150,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "telefono",
                headerName: "Telefono",
                width: 100,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
            ]}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div>
      </Modal>
    </>
  );
};
